import { Box, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { NextHead } from '../components/NextHead';
import { getStaticPropsWithTranslations } from '../utils/translation';

const NotFoundPage = () => {
  return (
    <>
      <NextHead pageName="404 - Page Not Found" />
      <Stack
        flex="1"
        alignItems="center"
        spacing={1}
        height="100%"
        justifyContent="center"
        py={4}
      >
        <Typography
          variant="h3"
          color="textSecondary"
          py={4}
          textAlign="center"
        >
          404 - Page Not Found
        </Typography>
        <Box flex={1} position="relative" width="100%">
          <Image src="/404.svg" fill alt="404 - Page Not Found" />
        </Box>
      </Stack>
    </>
  );
};

export const getStaticProps = getStaticPropsWithTranslations(['common']);

export default NotFoundPage;
